import React, { useState } from 'react';

function IPAddressList({ onChanged }) {
    const [ipList, setIpList] = useState([]);
    const [newIP, setNewIP] = useState('');

    const addIP = () => {
        if (newIP.trim() !== '') {
            const updatedList = [...ipList, newIP.trim()];
            setIpList(updatedList);
            setNewIP('');
            if (onChanged) {
      
                onChanged(updatedList);
            }
        }
    };

    const removeIP = (index) => {
        const updatedList = ipList.filter((_, i) => i !== index);
        setIpList(updatedList);
        if (onChanged) {
           
            onChanged(updatedList);
        }
    };

    return (
        <div>
            <h2>IP Address List</h2>
            <ul>
                {ipList.map((ip, index) => (
                    <li key={index}>
                        {ip} <button onClick={() => removeIP(index)}>Remove</button>
                    </li>
                ))}
            </ul>
            <input
                type="text"
                value={newIP}
                onChange={(e) => setNewIP(e.target.value)}
                placeholder="Enter IP Address"
            />
            <button onClick={addIP}>Add IP</button>
        </div>
    );
}

export default IPAddressList;
