import React, { useState, useEffect, useRef } from "react";
import validator from "validator";
import Alert from "react-bootstrap/Alert";
import { createUserService } from "../../services/bffServices/createUser";
import Placeholder from "react-bootstrap/Placeholder";
import Spinner from "react-bootstrap/Spinner";
import IPAddressList from "../ipAddressList";
import { createIpListService } from "../../services/bffServices/createIpList";

const saltRounds = 10;

function CreateIpListDetailsComponent() {
	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [ipAddresses, setIpAddresses] = useState([]);
	const [dateCreated, setDateCreated] = useState(new Date().getUTCDate());
	const [nextButton, setNextButton] = useState("Next");
	const [stepupError, setStepupError] = useState("");
	const [userId, setUserId] = useState();
	const [userName, setUserName] = useState();
	const [email, setEmail] = useState();


    const createIpList = async () => {
        var payload = {
            id: id,
            name: name,
            ipAddresses: ipAddresses, 
            dateCreated: dateCreated,
            dummyAccount: {
                userId: userId,
                userName: userName,
                email: email
            }
        };

        console.log(payload)

        var results = await createIpListService(payload);
        var resultsMess = "error101";

        if (results.status != "Success") {
            resultsMess = results.message;
        }

        if (results.status == "Success") {
            resultsMess =
                "Successfully Created Ip List: " + results.name + "/" + results.id;
        }

        if (results.code !== 2) {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="success" variant="success">
                            Ip List has been created
                        </Alert>
                    </>
                );
            });

            setNextButton("Next");
        }
        if (results.code === 2) {
            setStepupError(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Error has occured: {results.message}
                            <br />
                        </Alert>
                    </>
                );
            });
            setNextButton("Next");
        }
        return results;
    };

	const createIpListValidation = async () => {
        console.log("beginning validation")
		if (
			id != "" &&
			name != "" &&
			ipAddresses != [] &&
			userId != "" &&
			userName != "" &&
			email != ""
		) {
            console.log("fields are valid")
			setStepupError("");
			if (validator.isEmail(email)) {
                console.log("email is email")
				setNextButton(() => {
					return (
						<>
							<Spinner animation="grow" size="sm" />
						</>
					);
				});
				setStepupError(() => {
					return (
						<>
							<Placeholder xs={6} />
						</>
					);
				});
                console.log("calling createIpList")
                
				var results = await createIpList();
			} else {
				setStepupError(() => {
					return (
						<>
							<Alert key="danger" variant="danger">
								An incorrect email format has been entered, correct and
								resubmit.
							</Alert>
						</>
					);
				});
			}
		} else {
			setStepupError(() => {
				return (
					<>
						<Alert key="danger" variant="danger">
							Entries missing, please validate all fields have been filled out!
						</Alert>
					</>
				);
			});
		}
	};

	return (
		<>
			<small>
				<table width="75%" align="center" border="0">
					<tr>
						<td width="20%">
							<label>Id</label>
						</td>
						<td>
							<input
								class="form-control form-control-sm"
								onChange={(event) => setId(event.target.value)}
								type="text"
								autoComplete="text"
								name="id"
								id="id"
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Name</label>
						</td>
						<td>
							<input
								class="form-control form-control-sm"
								type="text"
								autoComplete="text"
								onChange={(event) => setName(event.target.value)}
								name="name"
								id="name"
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>ipAddresses</label>
						</td>
						<td>
							<IPAddressList onChanged={(updatedList) => {
                                setIpAddresses(updatedList);
                            }}></IPAddressList>
						</td>
					</tr>
					<tr>
						<td colspan="2">
							<hr />
						</td>
					</tr>
					<tr>
						<td>
							<label>User Id</label>
						</td>
						<td>
							<input
								class="form-control form-control-sm"
								type="text"
								autoComplete="text"
								onChange={(event) => setUserId(event.target.value)}
								name="userId"
								id="userId"
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>User Name</label>
						</td>
						<td>
							<input
								class="form-control form-control-sm"
								type="text"
								autoComplete="text"
								name="userName"
								onChange={(event) => setUserName(event.target.value)}
								id="userName"
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>User Email</label>
						</td>
						<td>
							<input
								class="form-control form-control-sm"
								type="text"
								autoComplete="text"
								name="userEmail"
								onChange={(event) => setEmail(event.target.value)}
								id="userEmail"
							/>
						</td>
					</tr>
					<tr>
						<td colspan="2" align="right">
							<button
								class="btn btn-primary mb-2"
								type="button"
								onClick={createIpListValidation}
							>
								{nextButton}
							</button>
						</td>
					</tr>
					<tr>
						<td colspan="2" align="center">
							{stepupError}
						</td>
					</tr>
				</table>
			</small>
		</>
	);
}

export default CreateIpListDetailsComponent;
