import { getUserProgressByItemId } from '../services/bffServices/getUserDetails';

//../../services/bffServices/getUserDetails';


const options = { 
    method: 'POST', 
    headers: new Headers({ 
      'Content-Type': 'application/json',
      'sb-application-name': 'sb-site-ui',
      'sb-application-key': 'jmd-test',
      'sessionID' : sessionStorage.getItem("sessionID"),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
    }), 
    mode: 'cors',
    credentials: 'omit'
  };

export async function transformTableOfContents(id){

    // console.log("transformTableOfContents" + id)
    
    const endpoint = process.env.REACT_APP_BFF + '/bff/v1.0/textbookContents/' + id;
    options.method = 'GET';
  
    let tocResponse =
      await fetch(endpoint,options)
        .then(response => {
          return response.json();
        })
        .catch(function(err) {
          console.log(err);
        });;

        if(tocResponse != null && tocResponse != undefined && Object.keys(tocResponse).length > 0){
          localStorage.setItem('TOC', JSON.stringify(tocResponse));
      }

    return tocResponse;


}

export async function nextLastModule(payload, moduleId){
  // console.log("thePayload" + JSON.stringify(payload));

  var resultsPayload = {
    "nextModule":"",
    "prevModule": ""
  }

  for( var c=0; c < payload.length; c++){
    for(var m=0; m < payload[c].module.length; m++){
      if (payload[c]?.module[m]?.id == moduleId){
        resultsPayload.nextModule = payload[c]?.module[m+1]?.id || "END";
        resultsPayload.prevModule = payload[c]?.module[m-1]?.id || "BEGINING";
      }
    }
  }

  // console.log("nextPrev" + JSON.stringify(resultsPayload));

  return resultsPayload;
}

export async function addStatusToTOC(payload){
 

  for(let c=0; c < payload.length; c++)
  {
    for(let m=0; m < payload[c].module.length; m++){
      var moduleStatus = await getUserProgressByItemId("MODULE", payload[c].module[m].id);
      var quizGrade = await getUserProgressByItemId("QUIZ", payload[c].module[m].id);

      if(quizGrade == "NOT STARTED"){
        quizGrade = 0
      }
      payload[c].module[m].moduleStatus = moduleStatus;
      payload[c].module[m].moduleGrade = quizGrade + "%";

    }
  }

  // console.log("TOCPAYLOAD" + JSON.stringify(payload));

  return payload;
}