import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SleekButton from "./sleekButton";
import ParalaxImage from "./paralaxImage";
import banner from "../assets/images/Banner2.png";
import { Container } from "react-bootstrap";
import Card from "./card";
import ModelViewbox from "./modelViewbox";
import BannerCarousel from "./carousel/bannerCarousel";
import ProductEvolutionImage from "../assets/images/Product Evolution.png";
import VideoThumbnail from "../assets/images/video-poster.png"
import {
	Player,
	ControlBar,
	ReplayControl,
	ForwardControl,
	CurrentTimeDisplay,
	TimeDivider,
	PlaybackRateMenuButton,
	VolumeMenuButton,
	BigPlayButton,
} from "video-react";

const strings = ["Text 1", "Text 2", "Text 3"];

function AboutUsPage() {
	const [currentTextIndex, setCurrentTextIndex] = useState(0);
	const [isMobile, setIsMobile] = useState(false);

	// const refreshPage = () => {
	// 	var refreshInd_hp = sessionStorage.getItem("hasRefresh");
	// 	var refreshInd_ab = sessionStorage.getItem("hasRefresh-about");


	// 	if (refreshInd_hp === "true") {
	// 		sessionStorage.setItem("hasRefresh-about", "true");
	// 		setTimeout(() => {
	// 			window.location.reload();
	// 		}, 500); // 500 milliseconds delay
	// 	}

	// 	if (refreshInd_hp === null) {
	// 		sessionStorage.setItem("hasRefresh-about", "true");
	// 		setTimeout(() => {
	// 			window.location.reload();
	// 		}, 500); // 500 milliseconds delay
	// 	}
	// };

	function getFoundersBox() {
		const cards = [
			<Card
				backgroundColor={"#054bb4"}
				color="white"
				title={"Carolyne Hutt"}
				subtitle={"Customer Execution & Vision"}
				description={
					"15yrs combined emerging small businesses | Accountant | Analytical | Fierce | BA specialization in communications, CPA, MBA"
				}
			/>,

			<Card
				backgroundColor={"#043A8B"}
				color={"white"}
				title={"Andrei Dacko"}
				subtitle={"Product Vision & Execution"}
				description={
					"27yrs combined K-12 & Higher Ed. STEM education | EdTech sales account executive | Management | Entrepreneur | Disruptor | B.Sc., B.Ed., MDes"
				}
			/>,
			<Card
				backgroundColor={"#032963"}
				color={"white"}
				title={"Jamie McLean-Davis"}
				subtitle={"Web Development & IT"}
				description={
					"11yrs combined Associate Director | Manager | Full Stack Developer | Strategy, Delivery, and Operations | Out-of-the-Box Thinker | Jack-of-All-Trades | A.A.S. Comp.Sci."
				}
			/>,
			<Card
				image=""
				color={"white"}
				title={"Andrew Catalano"}
				subtitle={"Founder, CEO"}
				backgroundColor={"#02193B"}
				description={
					"PhD | Cell, Molecular, & Systems Biology | Postdoc | X-Ray Crystallography | 7 years university instructor | 18+ years 3D animation "
				}
			/>,
		];

		return (
			<div className="grid-container">
				{cards.map((item, index) => (
					<div key={index}>{item}</div>
				))}
			</div>
		);
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTextIndex((prevIndex) => (prevIndex + 1) % strings.length);
		}, 10000);

		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Check initial screen width
		window.addEventListener("resize", handleResize); // Update on resize
		// refreshPage();

		return () => {
			window.removeEventListener("resize", handleResize);
			clearInterval(interval);
		};
	}, []);

	return (
		<div style={{ position: "relative", "min-height": "calc(100vh - 150px)" }}>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1"
			></meta>
			<div className="grid-container">
				<div className="rotating">
					<div className="wrapper">
						<h1 style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}>
							WE ARE
						</h1>
						<div className="words">
							<span
								className="word-span"
								style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}
							>
								EDUCATORS.
							</span>
							<span
								className="word-span"
								style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}
							>
								DISRUPTERS.
							</span>
							<span
								className="word-span"
								style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}
							>
								SCIENTISTS.
							</span>
							<span
								className="word-span"
								style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}
							>
								ANIMATORS.
							</span>
							<span
								className="word-span"
								style={{ fontWeight: "bold", fontSize: isMobile ? 30 : 54 }}
							>
								EDUCATORS.
							</span>
						</div>
					</div>
				</div>
			</div>

			<div
				className="grid-container"
				style={{
					paddingTop: "50px",
					paddingBottom: "50px",
					backgroundColor: "#f6f7f8",
				}}
			>
				<Container>
					<h2 align="left" style={{ fontSize: "42px" }}>
						Our Mission.
					</h2>
					<Row>
						{!isMobile ? (
							<Col>
								<ModelViewbox src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index_hp_final.html?model=objects/1-Subatomic-1-Subatomic-3-StartingGroundUp-1-VesicleMicrotubule-3&scale=0.2&colors=[1AD212,16C1BF,BD3C98,D26B22,B3452E,1DB373,B75ED4,D26B22,16A57F,D26B22,16A57F,D8D1B6,216113,5148BA,ffffff]"}/>
							</Col>
						) : (
							<></>
						)}
						<Col>
							<h4 align="left">
								Students can now see, hear, and interact with atoms, genes,
								cells, evolution, and more.
							</h4>
							<br></br>
							<Row>
								<Col>
									<p align="left">
										Our Animated Textbooks represent a highly visual approach to
										understanding biology and a long overdue alternative to the
										traditional textbook. We want students to not just memorize,
										but truly understand biology.
									</p>
								</Col>
								{!isMobile ? (
									<Col>
										<p align="left">
											We therefore integrate data from across the literature to
											create comprehensive resources consisting of high quality,
											accurate animations, 3D interactive structures, text,
											figures, LMS-quizzes, and much more, all representative of
											everything that we know today on a given subject.
										</p>
									</Col>
								) : (
									<></>
								)}
							</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<div
				className="grid-container"
				style={{
					paddingBottom: "100px",
				}}
			>
				<Container className="grid-container" style={{ paddingTop: "20px" }}>
					<div>
						<h2 align="left" style={{ fontSize: "42px" }}>
							Our Founders.
						</h2>
						<div style={{ textAlign: "left" }}>{getFoundersBox()}</div>
					</div>
				</Container>
			</div>

			<div style={{ paddingBottom: "50px", backgroundColor: "#f6f7f8" }}>
				<Container className="grid-container" style={{ paddingTop: "50px" }}>
					<div>
						<h2 align="left" style={{ fontSize: "42px" }}>
							Our Story.
						</h2>
						<p align="left" style={{ fontWeight: "bold" }}>
							We are scientists, educators, and animators in one.
						</p>
						<div style={{ textAlign: "left" }}>
							<p>
								Andrew Catalano, fueled by a passion for biology, encountered a
								challenge during his undergraduate years: the lack of effective
								visualization resources hindering the understanding of complex
								concepts. This led him to shift his focus during postdoctoral
								studies to structural biology, aiming to gain deeper insights
								into cellular and molecular mechanisms. To his surprise, he
								discovered that the necessary data for accurate visualizations
								had long existed in scientific literature, yet a significant gap
								separated this wealth of information from what was presented in
								undergraduate textbooks.
							</p>
							<p>
								Recognizing the imperative to bridge this educational divide,
								Andrew, equipped with a background in biology, education, and 3D
								animation cultivated during eight years of self-teaching,
								founded Smart Biology. Departing from academia, he embarked on a
								mission to redefine biology education by seamlessly integrating
								visually compelling representations, enabling students to grasp
								the intricacies of the cellular and molecular world.
							</p>
						</div>
					</div>

					<div className="grid-container">
						<div
							className="story-card"
							style={{
								padding: "20px",
								borderRadius: "20px",
								backgroundColor: "white",
							}}
						>
							<div>
								<h4>Founded In</h4>
								<h3
									style={{ fontWeight: "bold", fontSize: 54, color: "#054bb4" }}
								>
									2015
								</h3>
							</div>
						</div>
						<div style={{ gridRow: "span 2 / auto" }}>
							<div
								className="story-card"
								style={{
									padding: "20px",
									borderRadius: "20px",
									height: "100%",
									backgroundColor: "white",
								}}
							>
								<div>
									<h4>Animated Video Lessons</h4>
									<h3
										style={{
											fontWeight: "bold",
											fontSize: 54,
											color: "#054bb4",
										}}
									>
										1000+
									</h3>
								</div>
							</div>
						</div>
						<div
							className="story-card"
							style={{
								padding: "20px",
								borderRadius: "20px",
								backgroundColor: "white",
							}}
						>
							<div>
								<h4>Classroom Students Served</h4>
								<h3
									style={{ fontWeight: "bold", fontSize: 54, color: "#054bb4" }}
								>
									16,600+
								</h3>
							</div>
						</div>
					</div>
				</Container>
			</div>

			<Container style={{
					paddingTop: "50px",
					paddingBottom: "50px",
					gap: '60px'
				}}>
			<h2 align="left" style={{ fontSize: "42px"}}>
							Diving Deeper.
						</h2>
						<br></br>
			<div className="grid-container" >
				<div>
					<Player
					
						playsInline
						poster={VideoThumbnail}
						src="https://storage.googleapis.com/sb-general/Smart-Biology-90-Sec-Explainer-Video-Bit-30vs.mp4"
					>
						<BigPlayButton position="center" />
						<ControlBar>
							<ReplayControl seconds={10} order={1.1} />
							<ForwardControl seconds={30} order={1.2} />
							<CurrentTimeDisplay order={4.1} />
							<TimeDivider order={4.2} />
							<PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
							<VolumeMenuButton />
						</ControlBar>
					</Player>
				</div>
				<img style={{width: '100%'}} src={ProductEvolutionImage}/>
			</div>
			</Container>

			<BannerCarousel></BannerCarousel>
		</div>
	);
}

export default AboutUsPage;
