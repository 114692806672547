import Accordion from 'react-bootstrap/Accordion'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useEffect } from "react";
import { transformTableOfContents, addStatusToTOC } from '../../services/unitTransformer';
import { render } from '@testing-library/react';
import Spinner from 'react-bootstrap/Spinner';
import { getUserProgressByItemId } from '../../services/bffServices/getUserDetails';

function ChapterList(props) {
    const language = sessionStorage.getItem("lang") || "EN"
    const [tableContents, setTableContents] = useState(() => {
        return (
            <div align="center"><br />
                <Spinner align="center" animation="border" />
            </div>
        )
    });
    const [moduleStatusIndicator, setModuleStatusIndicator] = useState(() => {
        return (
            <>
                <Col sm={1} md={1} lg={1}><i class="info-circle"></i></Col>
            </>
        )

    })

    const completionCheck = (moduleId) => {
        var moduleStatus = getUserProgressByItemId(moduleId);

        if (moduleStatus == "COMPLETED") {
            return (

                <>
                    <Col sm={1} md={1} lg={1}><i class="info-circle complete"></i></Col>
                </>
            )
        }
    }

    const renderContent = async (mocUnitData, activeChapter) => {
        var ltiLocked = sessionStorage.getItem("ltiLock")
        var ltiChapter = sessionStorage.getItem("ltiChapter");
        var publicSiteChapterlist = [];

        return (
            <div>
                {props.publicSite != true &&
                    <div  align="center"><h3 id='tableOFContentsHeader'>Table of Cons</h3></div>

                }
                <div class="tocBox" >
                    <Accordion defaultActiveKey={activeChapter} alwaysOpen flush style={{ padding: 0 }}>
                        {Object.keys(mocUnitData).map(function (key) {
                            var displayItem = true;
                            if (ltiLocked == "Y") {
                                if (mocUnitData[key]?.id != ltiChapter) {
                                    displayItem = "none";
                                }
                            }

                            return (

                                <Accordion.Item eventKey={mocUnitData[key]?.id} style={{ display: displayItem, padding: 0 }}>
                                    <Container style={{ padding: 0 }} >
                                        {language != "FR" &&
                                            <Accordion.Header ><small style={{ 'font-weight': "300" }}>{mocUnitData[key]?.chapterTitle}</small></Accordion.Header>
                                        }
                                        {language == "FR" &&
                                            <Accordion.Header >{mocUnitData[key]?.title_fr}</Accordion.Header>
                                        }

                                        <Accordion.Body style={{ padding: 0 }}>
                                            <Container style={{ padding: 0 }}>
                                                <Row>

                                                    {props.productPage != "true" &&
                                                        <>
                                                            {/* <Col><div align="right">Score</div></Col> */}
                                                        </>
                                                    }
                                                </Row>
                                                {Object.keys(mocUnitData[key].module).map(function (module) {

                                                    return (

                                                        <Container style={{ padding: "5px" }}>
                                                            <Row>
                                                                {props.productPage != "true" && //getUserProgressByItemId("MODULE", mocUnitData[key].module[module].id) == "COMPLETED" &&

                                                                    <>
                                                                        {props.module == "true" &&
                                                                            <>

                                                                                {mocUnitData[key].module[module].moduleStatus == "COMPLETE" &&
                                                                                    <>
                                                                                        <Col sm={1} md={1} lg={1}><i class="info-circle complete"></i></Col>
                                                                                    </>
                                                                                }
                                                                                {mocUnitData[key].module[module].moduleStatus != "COMPLETE" &&
                                                                                    <>
                                                                                        <Col sm={1} md={1} lg={1}><i class="info-circle"></i></Col>
                                                                                    </>
                                                                                }


                                                                                <Col><a href={"module?id=" + mocUnitData[key].module[module].id}>
                                                                                    {language != "FR" &&
                                                                                        <strong>{mocUnitData[key].module[module].title_en}</strong>
                                                                                    }
                                                                                    {language == "FR" &&
                                                                                        <strong>{mocUnitData[key].module[module].title_fr}</strong>
                                                                                    }
                                                                                </a>
                                                                                    <Col sm={12}>
                                                                                        <ul>
                                                                                            {mocUnitData[key].module[module].lesson.map(function (lesson) {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {language != "FR" &&
                                                                                                            <li style={{ 'list-style-position': "outside" }}><small>{lesson.title_en}</small></li>
                                                                                                        }
                                                                                                        {language == "FR" &&
                                                                                                            <li style={{ 'list-style-position': "outside" }}><small>{lesson.title_fr}</small></li>
                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </ul>
                                                                                    </Col>
                                                                                </Col>

                                                                            </>
                                                                        }
                                                                        {props.module != "true" &&
                                                                            <>
                                                                                {props.publicSite != true &&
                                                                                    <>
                                                                                        <Col sm={1} md={1} lg={1}><i class="info-circle"></i></Col>
                                                                                    </>
                                                                                }
                                                                                <Col>
                                                                                    {props.publicSite != true &&
                                                                                        <>
                                                                                            <a href={"textbook/module?id=" + mocUnitData[key].module[module].id}>
                                                                                                {language != "FR" &&
                                                                                                    <strong style={{ 'font-weight': "300" }}>{mocUnitData[key].module[module].title_en}</strong>
                                                                                                }
                                                                                                {language == "FR" &&
                                                                                                    <strong style={{ 'font-weight': "300" }}>{mocUnitData[key].module[module].title_fr}</strong>
                                                                                                }
                                                                                            </a>
                                                                                        </>
                                                                                    }
                                                                                    {props.publicSite == true &&
                                                                                        <>
                                                                                            {language != "FR" &&
                                                                                                <div style={{ padding: 0, margin: 0, left: 0 }}>
                                                                                                    <strong style={{ 'font-weight': "300" }}>{mocUnitData[key].module[module].title_en}</strong>
                                                                                                </div>
                                                                                            }
                                                                                            {language == "FR" &&
                                                                                                <strong style={{ 'font-weight': "300" }}>{mocUnitData[key].module[module].title_fr}</strong>
                                                                                            }
                                                                                        </>
                                                                                    }

                                                                                    <Col sm={12}>
                                                                                        <ul>
                                                                                            {mocUnitData[key].module[module].lesson.map(function (lesson) {
                                                                                                return (
                                                                                                    <div>
                                                                                                        {language != "FR" &&
                                                                                                            <li style={{ 'list-style-position': "outside", 'font-weight': "300" }}><small>{lesson.title_en}</small></li>
                                                                                                        }
                                                                                                        {language == "FR" &&
                                                                                                            <li style={{ 'list-style-position': "outside", 'font-weight': "300" }}><small>{lesson.title_fr}</small></li>
                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </ul>
                                                                                    </Col>
                                                                                </Col>

                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                                {props.productPage == "true" &&
                                                                    <>
                                                                        {/* <Col sm={1} md={1} lg={1}><i class="info-circle"></i></Col> */}
                                                                        <Col><strong>{mocUnitData[key].module[module].title_en}</strong></Col>
                                                                    </>
                                                                }
                                                            </Row>
                                                            <Col sm={1} md={1} lg={1}></Col>

                                                        </Container>
                                                    );
                                                })}
                                            </Container>
                                        </Accordion.Body>
                                    </Container>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            </div>
        );
    }
    useEffect(async () => {
        var mocUnitData3 = localStorage.getItem("TOC");
        var activeChapter = localStorage.getItem("activeChapter");

        if (props.publicSite == true) {
            mocUnitData3 = await transformTableOfContents(props.id);
        } else {
            if (localStorage.getItem('TOC') == null || localStorage.getItem("TOC") == undefined) {
                mocUnitData3 = await transformTableOfContents(props.id);
            } else {
                mocUnitData3 = JSON.parse(localStorage.getItem("TOC"));

            }
        }

        const renderStuffTemp = await renderContent(mocUnitData3, activeChapter);
        setTableContents(renderStuffTemp);

        const newTOCWithStatus = await addStatusToTOC(mocUnitData3)
        const renderStuff = await renderContent(newTOCWithStatus, activeChapter)

        setTableContents(renderStuff);

    }, []);

    return (
        <>
            {tableContents}
        </>
    );
}

export default ChapterList